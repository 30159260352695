<template>
  <validation-observer ref="rules" v-slot="{ invalid }">
    <b-card no-body>
      <b-card-header>
        <b-card-title>{{ $t("Business Profile") }}</b-card-title>
        <div style="display:flex">
          <b-form-checkbox checked="true" v-model="form_data.requiredDim" class="custom-control-success"
                           name="check-button" @input="updateStatus(form_data.requiredDim)" switch>
            <span class="switch-icon-left">
                <feather-icon icon="CheckIcon"/>
            </span>
            <span class="switch-icon-right">
                <feather-icon icon="XIcon"/>
            </span>
          </b-form-checkbox>
          Weighting Required
        </div>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col md="3">
            <validation-provider :name="$t('Label Name')" rules="required">
              <b-form-group :label="$t('Label Name')" slot-scope="{ valid, errors }">
                <b-form-input id="name" v-model="form_data.name" :disabled="isEdit"
                              :state="errors[0] ? false : valid ? true : null"/>
                <b-form-invalid-feedback>
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="3">
            <validation-provider :name="$t('Company Name')" rules="required">
              <b-form-group :label="$t('Company Name')" slot-scope="{ valid, errors }">
                <b-form-input v-model="form_data.displayName" :state="errors[0] ? false : valid ? true : null"/>
                <b-form-invalid-feedback>
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="3">
            <validation-provider :name="$t('Company Type')" rules="required">
              <b-form-group :label="$t('Company Type')" slot-scope="{ valid, errors }">
                <v-select
                    :options="typeList"
                    label="codeDescription"
                    @input="selType"
                    v-model="form_data.orgType"
                    :disabled="isEdit" :state="errors[0] ? false : valid ? true : null"
                >
                </v-select>
                <!-- <b-form-select v-model="form_data.org_type" :options="typeList.map((e) => ({ value: e.codeName, text: e.codeDescription }))" :disabled="isEdit" :state="errors[0] ? false : valid ? true : null" /> -->
                <b-form-invalid-feedback>
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="3">
            <validation-provider :name="$t('KE FOX code')" rules="required">
              <b-form-group :label="$t('KE FOX code')" slot-scope="{ valid, errors }">
                <b-form-input v-model="form_data.keAccountNumber" :state="errors[0] ? false : valid ? true : null"/>
                <b-form-invalid-feedback>
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="3">
            <validation-provider :name="$t('Email')" rules="required|email">
              <b-form-group :label="$t('Email')" slot-scope="{ valid, errors }">
                <b-form-input id="email" v-model="form_data.orgEmail" :state="errors[0] ? false : valid ? true : null"/>
                <b-form-invalid-feedback>
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="3">
            <validation-provider :name="$t('Address')" rules="required">
              <b-form-group :label="$t('Address')" slot-scope="{ valid, errors }">
                <b-form-input v-model="form_data.address" :state="errors[0] ? false : valid ? true : null"/>
                <b-form-invalid-feedback>
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="3">
            <validation-provider :name="$t('Country')" rules="">
              <b-form-group :label="$t('Country')" slot-scope="{ valid, errors }">
                <b-form-input v-model="form_data.country" :state="errors[0] ? false : valid ? true : null"/>
                <b-form-invalid-feedback>
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="3">
            <validation-provider :name="$t('Country Code')" rules="">
              <b-form-group :label="$t('Country Code')" slot-scope="{ valid, errors }">
                <b-form-input v-model="form_data.countryCode" :state="errors[0] ? false : valid ? true : null"/>
                <b-form-invalid-feedback>
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="3">
            <validation-provider :name="$t('Pick Up Address')" rules="">
              <b-form-group :label="$t('Pick Up Address')" slot-scope="{ valid, errors }">
                <b-form-input v-model="form_data.pickUpAddress" :state="errors[0] ? false : valid ? true : null"/>
                <b-form-invalid-feedback>
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="3">
            <validation-provider :name="$t('Pick Up District')" rules="">
              <b-form-group
                  :label="$t('Pick Up District')"
                  label-for="Pick Up district"
                  slot-scope="{ valid, errors }"
              >
                <b-form-input v-model="form_data.pickUpDistrict" :state="errors[0] ? false : valid ? true : null"/>
                <b-form-invalid-feedback>
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="3">
            <validation-provider :name="$t('Pick Up Area')" rules="">
              <b-form-group
                  :label="$t('Pick Up Area')"
                  label-for="Pick Up area"
                  slot-scope="{ valid, errors }"
              >
                <v-select
                    :options="areaList"
                    label="codeDescription"
                    @input="selArea"
                    v-model="pickUpArea"
                    :state="errors[0] ? false : valid ? true : null"
                />
                <b-form-invalid-feedback>
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="3"></b-col>
          <b-col md="3">
            <validation-provider :name="$t('Phone')" rules="required|numeric">
              <b-form-group :label="$t('Phone')" slot-scope="{ valid, errors }">
                <b-form-input v-model="form_data.orgPhone" :state="errors[0] ? false : valid ? true : null"/>
                <b-form-invalid-feedback>
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="3">
            <validation-provider :name="$t('Sales Name')" rules="">
              <b-form-group :label="$t('Sales Name')" slot-scope="{ valid, errors }">
                <b-form-input v-model="form_data.salesName" :state="errors[0] ? false : valid ? true : null"/>
                <b-form-invalid-feedback>
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="3"></b-col>
          <b-col md="3">
            <b-form-group :label="$t('Consignee Info in CUS Order Inbound Report')">
              <b-form-checkbox v-model="form_data.isShowConsigneeInfoInCusReport">
                Enable
              </b-form-checkbox>
            </b-form-group>

          </b-col>
          <b-col md="3">
            <validation-provider :name="$t('Status Webhook URL')" rules="">
              <b-form-group :label="$t('Status Webhook URL')" slot-scope="{ valid, errors }">
                <!-- <v-select
                  class="sp-st"
                  :options="spList"
                  label="url"
                  v-model="form_data.statusUrl"
                  :disabled="true" :state="errors[0] ? false : valid ? true : null"
                ></v-select> -->
                <b-form-input v-model="form_data.statusUrl" :state="errors[0] ? false : valid ? true : null"/>
                <b-form-invalid-feedback>
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

          </b-col>
          <b-col md="3">
            <validation-provider :name="$t('Self Pick Vendor')" rules="">
              <b-form-group :label="$t('Self Pick Vendor')" slot-scope="{ valid, errors }">
                <v-select
                    class="sp-st"
                    :options="statusUrlList"
                    label="agent"
                    v-model="form_data.spVendor"
                    :disabled="true" :state="errors[0] ? false : valid ? true : null"
                    multiple
                ></v-select>
                <b-form-invalid-feedback>
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="3"></b-col>
          <b-col md="3"></b-col>
          <b-col md="3">
            <b-form-checkbox-group id="checkbox-group" name="selectedUrl" v-model="info.statusUrl"
                                   @input="clearUrl(info.statusUrl)">
              <b-form-checkbox v-for="(item,) in statusUrlList" :value="item.url" :checked="info.statusUrl"
                               :key="item.id">
                {{ item.name }}
              </b-form-checkbox>
            </b-form-checkbox-group>
          </b-col>
          <b-col md="4">
            <b-form-checkbox-group id="checkbox-group2" v-model="info.spVendor" @input="spChange(info.spVendor)"
                                   name="selectedSP">
              <b-form-checkbox v-for="(item, index) in spList" :value="item.agent" :key="index" switch>
                <span style="line-height:25px">{{ item.agent }}</span>
              </b-form-checkbox>
            </b-form-checkbox-group>
          </b-col>
        </b-row>
        <div class="text-right">
          <b-button variant="info" @click="save" :disabled="invalid">{{
              isEdit ? $t("Update") : $t("Create")
            }}
          </b-button>
        </div>
      </b-card-body>
    </b-card>
  </validation-observer>
</template>

<script>
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  BCard, BCardBody, BButton, BRow, BCol, BFormGroup, BFormInput, BCardHeader,
  BCardTitle, BFormInvalidFeedback
} from 'bootstrap-vue'

export default {
  components: {
    vSelect,
    ToastificationContent,
    BRow, BCol,
    BCard, BCardBody,
    // ValidationProvider,
    // ValidationObserver,
    BButton,
    BFormGroup, BFormInput,
    BCardHeader, BCardTitle,
    BFormInvalidFeedback,
  },
  props: {
    id: {
      type: Number
    },
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      typeList: [],
      form_data: {
        spVendor: [],
        statusUrl: null,
        requiredDim: true,
        isShowConsigneeInfoInCusReport: false,
      },
      info: {spVendor: [],},
      statusUrlList: [{
        id: 1,
        name: 'Is KEC',
        url: 'https://spider.kec-app.com/package/tracking/kectm',
        check: false
      }, {id: 2, name: 'Clear', url: '', check: false}],
      spList: [],
      areaList: [],
      pickUpArea: ''
    };
  },
  mounted() {
    this.queryOrgType()
    this.queryArea()
    this.querysp()
    if (this.id && Number(this.id) != 0) {
      this.getOrgCode()
    }
  },
  methods: {
    queryArea() {
      this.$http.get('/masterData/findAllByDataType', {params: {data_type: 'hkArea'}}).then(res => {
        this.areaList = res.data.data
      })
    },
    spChange(item) {
      this.form_data.spVendor = item.map(val => {
        return {agent: val}
      })
    },
    clearUrl(item) {
      for (let i = 0; i < item.length; i++) {
        if (item.length == 1) {
          this.form_data.statusUrl = item[0]
          this.info.statusUrl = [item[0]]
        }
        if (item.length > 1) {
          this.form_data.statusUrl = item[1]
          this.info.statusUrl = [item[1]]
        }
      }
    },
    selArea(d) {
      this.form_data.pickUpArea = d ? d.codeName : null
      this.pickUpArea = d ? d.codeDescription : null
    },
    save() {
      let that = this.form_data
      let vendorList = []
      if (that.spVendor) {
        vendorList = that.spVendor.map(val => {
          return val.agent
        })
      }
      let params = {
        address: that.address,
        country: that.country,
        countryCode: that.countryCode,
        displayName: that.displayName,
        fullfillmentInfoList: null,
        keAccountNumber: that.keAccountNumber,
        merchantList: [],
        name: that.name,
        orgEmail: that.orgEmail,
        orgPhone: that.orgPhone,
        orgType: that.orgType,
        salesName: that.salesName,
        spVendor: vendorList,
        statusUrl: that.statusUrl,
        storeCode: that.keAccountNumber,
        requiredDim: that.requiredDim,
        pickUpAddress: that.pickUpAddress,
        pickUpDistrict: that.pickUpDistrict,
        pickUpArea: that.pickUpArea,
        isShowConsigneeInfoInCusReport: that.isShowConsigneeInfoInCusReport,
      }
      if (this.isEdit) {
        params.id = this.id
        this.$http.post('admin/settings/organization/updateOrganization', params).then(res => {
          if (res.data.code === '200') {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Update customer Success',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            this.$router.push({name: 'customer-list2'})
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: 'XIcon',
                variant: 'danger',
              },
            })
          }
        })
      } else {
        this.$http.post('admin/settings/organization/createOrganization', params).then(res => {
          if (res.data.code === '200') {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Create customer Success',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            this.$router.push({name: 'customer-list2'})
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: 'XIcon',
                variant: 'danger',
              },
            })
          }
        })
      }
    },
    updateStatus(val) {
      console.info(val, 'val')
    },
    getOrgCode() {
      this.$http.post('admin/settings/organization/getInfoById', {id: this.id}).then(res => {
        const item = res.data.data
        this.form_data = item
        this.pickUpArea = item.pickUpArea
        const arr = []
        item.spVendor.map(i => {
          arr.push(i.agent)
        })
        this.info.spVendor = arr
        this.info.statusUrl = [item.statusUrl]
      })
    },
    queryOrgType() {
      this.$http.get('/masterData/findAllByDataType', {params: {data_type: 'orgType'}}).then(res => {
        this.typeList = res.data.data
      })
    },
    querysp() {
      this.$http.get('admin/settings/organization/getDistinctAgent').then(res => {
        this.spList = res.data.data
      })
    },
    selType(d) {
      if (d) {
        this.form_data.orgType = d.codeName
        this.form_data.type = d.codeDescription
        // if (d.codeName === 'MALL_TO_HOME') {
        //   this.isShowMall = false
        //   this.getMall()
        // } else {
        //   this.isShowMall = true
        // }
        // if (d.codeName == 'FULLFILLMENT_CUSTOMER') {
        //   this.isShowwms = true
        // } else {
        //   this.isShowwms = false
        // }
      } else {
        this.form_data.orgType = null
        this.form_data.type = null
      }
    },

    getMall() {
      this.$http.get('/masterData/findAllByDataType', {params: {data_type: 'mallName'}}).then(res => {
        this.mallList = res.data.data
      })
    },
  }
};
</script>

<style>
[dir] .sp-st .vs__selected {
  background-color: #ed6d01 !important;
  border: 0 solid rgba(60, 60, 60, 0.26) !important;
  border-radius: 0.357rem !important;
  margin: 4px 2px 0px 2px !important;
  padding: 0 0.25em !important;
}

.sp-st svg.feather {
  display: none !important;
}

.check-box .el-checkbox__inner {
  width: 18px;
  height: 18px;
  /* background-size: 100%; */
}

/* [dir] .check-box .el-checkbox__inner::after{
  width: 18px;
  height: 18px;
} */
[dir] .check-box .el-checkbox__input.is-checked .el-checkbox__inner, [dir] .el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #ed6d01;
  border-color: #ed6d01;
}

.check-box .el-checkbox__inner::after {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  content: "";
  height: 8px;
  position: absolute;
  top: 2px;
  width: 4px;
}
</style>
